/* html {
  font-size: 14px;
} */

.mosaic.mosaic-blueprint-theme {
  background: transparent !important;
}

.mosaic-window-body {
  padding: 16px;
}

.mosaic-window-body {
  padding: 16px;
}

.watermark {
  color: rgba(0, 0, 0, 0.2);
}

.not-typical-date {
  position: absolute;
  left: 14px;
  font-family: Roboto;
  font-size: 0.857rem;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: 0.17px;
  text-align: left;
  bottom: 0px;
  color: #e65100;
}

.nested-menu-item {
  overflow-y: hidden !important;
}

.nested-menu-item-left {
  margin-left: -215px;
}

/* Scrollbar */
*::-webkit-scrollbar {
  width: 7px;
  height: 7px;
  background-color: none;
}

*::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0);
  background-color: #babac080 !important;
}

/* firefox */
@supports not selector(::-webkit-scrollbar) {
  * {
    scrollbar-width: thin;
    scrollbar-color: #babac080 transparent;
  }
}

*::-moz-scrollbar {
  width: 7px;
  height: 7px;
  background-color: none;
}

*::-moz-scrollbar-thumb {
  border-radius: 10px;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0);
  background-color: #babac080 !important;
}

.marker-deck-label {
  color: transparent;
  background-color: transparent !important;
}

.gm-style-iw:focus-visible {
  outline: none;
}
