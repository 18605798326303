.gm-style .gm-style-iw-c,
.gm-style .gm-style-iw-d {
  padding: 0 !important;
  box-shadow: none !important;
  background: transparent !important;
  border-radius: unset !important;
  overflow: hidden !important;
}

.gm-ui-hover-effect,
.gm-style-iw-tc::after {
  display: none !important;
}